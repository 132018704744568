import "./App.css";
import Card from "./components/Card"

function App() {
  return (
    <Card />
  );
}

export default App;
